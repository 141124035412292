import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSettings.module.css';

const IconSettings = props => {
  const { rootClassName, className, styleStr } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75" />
    </svg>
  );
};

IconSettings.defaultProps = {
  rootClassName: null,
  className: null,
  styleStr: {},
};

const { string } = PropTypes;

IconSettings.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconSettings;
