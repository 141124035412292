import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form, PrimaryButton, FieldTextInput, NamedLink, Button } from '../../components';

import css from './SubscriptionForm.module.css';

const SubscriptionFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        intl,
        invalid,
        isFooter,
      } = fieldRenderProps;

      const { title, altTitle, subtitle } = props;

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div className={css.formContainer}>
            <div className={classNames(css.subscriptionAltTitle, { [css.subscriptionTitleFooter]: isFooter })}>{altTitle}</div>
            <div className={classNames(css.subscriptionTitle, { [css.subscriptionTitleFooter]: isFooter })}>{title}</div>
            <div className={css.subscriptionFormContainer}>
              <FieldTextInput
                type="email"
                id={formId ? `${formId}.email` : 'email'}
                name="email"
                autoComplete="email"
                placeholder="Your email"
              />
              <Button type="submit" className={classNames(css.subscriptionButton, { [css.subscriptionButtonFooter]: isFooter })}>Subscribe</Button>
            </div>
            <div className={css.subscriptionSubtitle}>{subtitle}</div>
          </div>
        </Form>
      );
    }}
  />
);

SubscriptionFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  form: null,
  inProgress: false,
};

const { string, bool } = PropTypes;

SubscriptionFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  form: string,
  inProgress: bool,
  intl: intlShape.isRequired,
};

const SubscriptionForm = compose(injectIntl)(SubscriptionFormComponent);
SubscriptionForm.displayName = 'SubscriptionForm';

export default SubscriptionForm;
