import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { types as sdkTypes, createImageVariantConfig } from '../../util/sdkLoader';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { hasQueryParam } from '../../util/urlHelpers';
import { getGroups, handleGroup } from '../../util/api';

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/GroupsPage/SET_INITIAL_STATE';

export const SHOW_USER_REQUEST = 'app/GroupsPage/SHOW_USER_REQUEST';
export const SHOW_USER_SUCCESS = 'app/GroupsPage/SHOW_USER_SUCCESS';
export const SHOW_USER_ERROR = 'app/GroupsPage/SHOW_USER_ERROR';

export const QUERY_LISTINGS_REQUEST = 'app/GroupsPage/QUERY_LISTINGS_REQUEST';
export const QUERY_LISTINGS_SUCCESS = 'app/GroupsPage/QUERY_LISTINGS_SUCCESS';
export const QUERY_LISTINGS_ERROR = 'app/GroupsPage/QUERY_LISTINGS_ERROR';

export const QUERY_REVIEWS_REQUEST = 'app/GroupsPage/QUERY_REVIEWS_REQUEST';
export const QUERY_REVIEWS_SUCCESS = 'app/GroupsPage/QUERY_REVIEWS_SUCCESS';
export const QUERY_REVIEWS_ERROR = 'app/GroupsPage/QUERY_REVIEWS_ERROR';

export const SET_CATEGORIES = 'app/GroupsPage/SET_CATEGORIES';
export const SET_CURRENT_CATEGORY = 'app/GroupsPage/SET_CURRENT_CATEGORY';

export const FETCH_GROUPS_REQUEST = 'app/GroupsPage/FETCH_GROUPS_REQUEST';
export const FETCH_GROUPS_SUCCESS = 'app/GroupsPage/FETCH_GROUPS_SUCCESS';
export const FETCH_GROUPS_ERROR = 'app/GroupsPage/FETCH_GROUPS_ERROR';

export const SET_USER_ID = 'app/GroupsPage/SET_USER_ID';

// ================ Reducer ================ //

const initialState = {
  userId: null,
  userListingRefs: [],
  userShowError: null,
  queryListingsError: null,
  reviews: [],
  queryReviewsError: null,
  categories: [],
  fetchingGroups: true,
  groups: [],
  groupsError: null,
  currentCategory: 'all'
};

export default function groupsPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };
    case SHOW_USER_REQUEST:
      return { ...state, userShowError: null };
    case SHOW_USER_SUCCESS:
      return state;
    case SHOW_USER_ERROR:
      return { ...state, userShowError: payload };

    case QUERY_LISTINGS_REQUEST:
      return {
        ...state,

        // Empty listings only when user id changes
        userListingRefs: payload.userId === state.userId ? state.userListingRefs : [],

        queryListingsError: null,
      };
    case QUERY_LISTINGS_SUCCESS:
      return { ...state, userListingRefs: payload.listingRefs };
    case QUERY_LISTINGS_ERROR:
      return { ...state, userListingRefs: [], queryListingsError: payload };
    case QUERY_REVIEWS_REQUEST:
      return { ...state, queryReviewsError: null };
    case QUERY_REVIEWS_SUCCESS:
      return { ...state, reviews: payload };
    case QUERY_REVIEWS_ERROR:
      return { ...state, reviews: [], queryReviewsError: payload };

    case SET_CATEGORIES:
      return { ...state, categories: payload };
    case SET_CURRENT_CATEGORY:
      return { ...state, currentCategory: payload };

    case FETCH_GROUPS_REQUEST:
      return { ...state, fetchingGroups: true, groupsError: null };
    case FETCH_GROUPS_SUCCESS:
      return { ...state, groups: payload, fetchingGroups: false };
    case FETCH_GROUPS_ERROR:
      return { ...state, groups: [], fetchingGroups: false, groupsError: payload };
    case SET_USER_ID:
      return { ...state, userId: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const showUserRequest = () => ({
  type: SHOW_USER_REQUEST,
});

export const showUserSuccess = () => ({
  type: SHOW_USER_SUCCESS,
});

export const showUserError = e => ({
  type: SHOW_USER_ERROR,
  error: true,
  payload: e,
});

export const queryListingsRequest = userId => ({
  type: QUERY_LISTINGS_REQUEST,
  payload: { userId },
});

export const queryListingsSuccess = listingRefs => ({
  type: QUERY_LISTINGS_SUCCESS,
  payload: { listingRefs },
});

export const queryListingsError = e => ({
  type: QUERY_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const queryReviewsRequest = () => ({
  type: QUERY_REVIEWS_REQUEST,
});

export const queryReviewsSuccess = reviews => ({
  type: QUERY_REVIEWS_SUCCESS,
  payload: reviews,
});

export const queryReviewsError = e => ({
  type: QUERY_REVIEWS_ERROR,
  error: true,
  payload: e,
});

export const setCategories = e => ({
  type: SET_CATEGORIES,
  payload: e,
});

export const setCurrentCategory = e => ({
  type: SET_CURRENT_CATEGORY,
  payload: e,
});

export const fetchGroupsRequest = () => ({ type: FETCH_GROUPS_REQUEST });

export const fetchGroupsSuccess = groups => ({
  type: FETCH_GROUPS_SUCCESS,
  payload: groups,
});

export const fetchGroupsError = e => ({
  type: FETCH_GROUPS_ERROR,
  error: true,
  payload: e,
});

export const setUserId = e => ({ type: SET_USER_ID, payload: e });

// ================ Thunks ================ //

export const queryUserListings = (userId, config, search = '') => (dispatch, getState, sdk) => {
  dispatch(queryListingsRequest(userId));

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    // variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  const variantPrefix = 'square-small';

  let queryParams = {
    author_id: userId,
    include: ['author', 'images'],
    'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
  };

  const filter = hasQueryParam({search}, 'pub_category', true);
  const listingFields = config?.listing?.listingFields || [];
  let filterCategories = [];
  const categories = listingFields.find(lf => lf.key == 'category');
  if (categories && categories.enumOptions && categories.enumOptions.length) {
    filterCategories = categories.enumOptions;
  }
  dispatch(setCategories(filterCategories));

  let currentCategory = 'all';
  if (filter) {
    let items = filter.split('=');
    if (items.length && items.length == 2) {
      if (items[1] !== 'all') {
        queryParams[items[0]] = items[1];
      }
      currentCategory = items[1];
    }
  }
  dispatch(setCurrentCategory(currentCategory));

  return sdk.listings
    .query(queryParams)
    .then(response => {
      // Pick only the id and type properties from the response listings
      const listingRefs = response.data.data.map(({ id, type }) => ({ id, type }));
      dispatch(addMarketplaceEntities(response));
      dispatch(queryListingsSuccess(listingRefs));
      return response;
    })
    .catch(e => dispatch(queryListingsError(storableError(e))));
};

export const queryUserReviews = userId => (dispatch, getState, sdk) => {
  sdk.reviews
    .query({
      subject_id: userId,
      state: 'public',
      include: ['author', 'author.profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      const reviews = denormalisedResponseEntities(response);
      dispatch(queryReviewsSuccess(reviews));
    })
    .catch(e => dispatch(queryReviewsError(e)));
};

export const showUser = () => (dispatch, getState, sdk) => {
  dispatch(showUserRequest(userId));
  return sdk.users
    .show({
      id: userId,
      include: ['profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(showUserSuccess());
      return response;
    })
    .catch(e => dispatch(showUserError(storableError(e))));
};

export const fetchGroups = () => async (dispatch, getState, sdk) => {
  dispatch(fetchGroupsRequest());

  let currentUser = await sdk.currentUser.show();
  let id = currentUser?.data?.data?.id;
  if (id) {
    dispatch(setUserId(id));
    getGroups({ id }).then(data => {
      dispatch(fetchGroupsSuccess(data));
    }).catch(e => {
      dispatch(fetchGroupsError(storableError(e)))
    });
  } else {
    dispatch(fetchGroupsSuccess([]));
  }
};

export const handleGroupFn = (body) => async (dispatch, getState, sdk) => {
  dispatch(fetchGroupsRequest());

  if (body?.action && ['accept', 'leave'].includes(body.action)) {
    plausible(body.action == 'accept' ? 'acceptInvite' : 'leaveTeam', {props: { teamId: body?.teamId }});
  }
  handleGroup(body).then(data => {
    dispatch(fetchGroups());
  }).catch(e => {
    dispatch(fetchGroupsError(storableError(e)))
  });
};

export const loadData = (params, search, config) => (dispatch, getState, sdk) => {
  // Clear state so that previously loaded data is not visible
  // in case this page load fails.
  dispatch(setInitialState());

  return Promise.all([
    dispatch(fetchCurrentUser()),
    dispatch(fetchGroups()),
    // dispatch(showUser()),
    // dispatch(queryUserListings(userId, config, search)),
  ]);
};
