import React, { Component } from 'react';
import { arrayOf, bool, func, string } from 'prop-types';
import classNames from 'classnames';

import { useRouteConfiguration } from '../../context/routeConfigurationContext';

import { FormattedMessage } from '../../util/reactIntl';
import { ensureCurrentUser } from '../../util/data';
import { propTypes } from '../../util/types';
import { pathByRouteName } from '../../util/routes';

import { Modal } from '../../components';

import PendingGroupsReminder from './PendingGroupsReminder';
import css from './ModalPendingGroups.module.css';
import { GROUPS_NAME, STATUS } from '../../util/userHelpers';

const MISSING_INFORMATION_MODAL_WHITELIST = [
  'LoginPage',
  'SignupPage',
  'ContactDetailsPage',
  'EmailVerificationPage',
  'GroupsPage',
  'PasswordResetPage',
  'StripePayoutPage',
];

const PENDING_GROUPS = 'PENDING_GROUPS';

class ModalPendingGroups extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showPendingGroupsReminder: null,
      hasSeenPendingGroupsReminder: false,
      quantity: 0,
    };
    this.handlePendingGroupsReminder = this.handlePendingGroupsReminder.bind(this);
  }

  componentDidUpdate() {
    const { currentUser, location } = this.props;
    const user = ensureCurrentUser(currentUser);
    this.handlePendingGroupsReminder(
      user,
      location
    );
  }

  handlePendingGroupsReminder(
    currentUser,
    newLocation
  ) {
    const routes = this.props.routeConfiguration;
    const whitelistedPaths = MISSING_INFORMATION_MODAL_WHITELIST.map(page =>
      pathByRouteName(page, routes)
    );

    // Is the current page whitelisted?
    const isPageWhitelisted = whitelistedPaths.includes(newLocation.pathname);

    // Track if path changes inside Page level component
    const pathChanged = newLocation.pathname !== this.props.location.pathname;
    const notRemindedYet =
      !this.state.showPendingGroupsReminder && !this.state.hasSeenPendingGroupsReminder;

    // Is the reminder already shown on current page
    const showOnPathChange = notRemindedYet || pathChanged;

    if (!isPageWhitelisted && showOnPathChange) {
      let publicData = currentUser?.attributes?.profile?.publicData || {};
      let groups = publicData[GROUPS_NAME] || [];

      if (groups && groups.length) {
        let currentGroups = groups.filter(g => ![STATUS.PENDING, STATUS.REJECTED, STATUS.REVOKED].includes(g.status));
        let pending = groups.filter(g => g.status == STATUS.PENDING);
        let hasGroups = currentGroups.length > 0;
        if (!hasGroups && pending.length) {
          this.setState({ showPendingGroupsReminder: PENDING_GROUPS, quantity: pending.length });
        }
      }
    }
  }

  render() {
    const {
      rootClassName,
      className,
      containerClassName,
      currentUser,
      onManageDisableScrolling,
    } = this.props;

    const user = ensureCurrentUser(currentUser);
    const classes = classNames(rootClassName || css.root, className);

    let content = null;

    const currentUserLoaded = user && user.id;
    if (currentUserLoaded) {
      if (this.state.showPendingGroupsReminder === PENDING_GROUPS) {
        content = (
          <PendingGroupsReminder className={classes} user={user} quantity={this.state.quantity}/>
        );
      }
    }

    const closeButtonMessage = (
      <FormattedMessage id="ModalPendingGroups.closePendingGroupsReminder" />
    );

    return (
      <Modal
        id="PendingGroupsReminder"
        containerClassName={containerClassName}
        isOpen={!!this.state.showPendingGroupsReminder}
        onClose={() => {
          this.setState({
            showPendingGroupsReminder: null,
            hasSeenPendingGroupsReminder: true,
          });
        }}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
        closeButtonMessage={closeButtonMessage}
      >
        {content}
      </Modal>
    );
  }
}

ModalPendingGroups.defaultProps = {
  className: null,
  rootClassName: null,
  currentUser: null,
};

ModalPendingGroups.propTypes = {
  id: string.isRequired,
  className: string,
  rootClassName: string,
  containerClassName: string,

  currentUser: propTypes.currentUser,
  onManageDisableScrolling: func.isRequired,

  // from useRouteConfiguration
  routeConfiguration: arrayOf(propTypes.route).isRequired,
};

const EnhancedModalPendingGroups = props => {
  const routeConfiguration = useRouteConfiguration();

  return <ModalPendingGroups routeConfiguration={routeConfiguration} {...props} />;
};

export default EnhancedModalPendingGroups;
