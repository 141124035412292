import React from 'react';
import { string, func, bool } from 'prop-types';
import classNames from 'classnames';

import { useConfiguration } from '../../context/configurationContext';

import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { displayPrice } from '../../util/configHelpers';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { isBookingProcessAlias } from '../../transactions/transaction';

import { camelCaseToTitleCase } from '../../util/string';

import { AspectRatioWrapper, Button, NamedLink, ResponsiveImage, ReviewRating } from '../../components';

import css from './ListingCard.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

const PriceMaybe = props => {
  const { price, publicData, config, intl } = props;
  const { listingType } = publicData || {};
  const validListingTypes = config.listing.listingTypes;
  const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice && price) {
    return null;
  }

  const isBookable = isBookingProcessAlias(publicData?.transactionProcessAlias);
  const { formattedPrice, priceTitle } = priceData(price, config.currency, intl);
  return (
    <div className={css.price}>
      <div className={css.priceValue} title={priceTitle}>
        {formattedPrice}
      </div>
      {isBookable ? (
        <div className={css.perUnit}>
          <FormattedMessage id="ListingCard.perUnit" values={{ unitType: publicData?.unitType }} />
        </div>
      ) : null}
    </div>
  );
};

export const SectionListingCardComponent = props => {
  const config = useConfiguration();
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    showAuthorInfo,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, publicData } = currentListing.attributes;
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const setActivePropsMaybe = setActiveListing
    ? {
        onMouseEnter: () => setActiveListing(currentListing.id),
        onMouseLeave: () => setActiveListing(null),
      }
    : null;

  const listingFeatures = currentListing?.attributes?.metadata || [];
  const FEATURES = [
    'drawWeight',
    'type'
  ];
  const computedFeatures = FEATURES.filter(f => listingFeatures[f] != undefined);
  const tags = currentListing?.attributes?.metadata?.tags || [];

  const imageVariants = firstImage?.attributes?.variants || [];
  const image = imageVariants['default'] ||imageVariants['square-small2x'] || imageVariants['square-small'] || imageVariants['landscape-crop2x'] || imageVariants['landscape-crop'];

  const brand = currentListing?.attributes?.publicData?.brand;
  const description = currentListing?.attributes?.description;
  const ratingData = [4, 3]; // Todo: Get data from source
  const rating = ratingData.length ? Math.floor(ratingData.reduce((a, b) => a + b, 0) / ratingData.length) : 0;

  return (
    <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
      <div className={css.sectionListingCardContainer}>
        <div>
          {image && image.url && <img className={css.sectionListingCardImage} src={image.url} />}
        </div>
        <div className={css.mainSectionInfo}>
          <div className={css.categoryBrand}>
            {brand}
          </div>
          <div className={css.categoryTitle}>
            <div>
              {richText(title, {
                longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                longWordClass: css.longWord,
              })}
            </div>
          </div>
          {description && <div className={css.categoryDescription}>
            {description}
          </div>}
          <div className={css.ratingContainer}>
            <ReviewRating rating={rating} reviewStarClassName={css.ratingStar}/>
            <div className={css.ratingCounter}>({ratingData.length})</div>
          </div>
        </div>
        {/* <AspectRatioWrapper
          className={css.aspectRatioWrapper}
          width={aspectWidth}
          height={aspectHeight}
          {...setActivePropsMaybe}
        >
          <LazyImage
            rootClassName={css.rootForImage}
            alt={title}
            image={firstImage}
            variants={variants}
            sizes={renderSizes}
          />
        </AspectRatioWrapper>
        <div className={css.info}>
          <PriceMaybe price={price} publicData={publicData} config={config} intl={intl} />
          <div className={css.mainInfo}>
            <div className={css.title}>
              {richText(title, {
                longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                longWordClass: css.longWord,
              })}
            </div>
            {showAuthorInfo ? (
              <div className={css.authorInfo}>
                <FormattedMessage id="ListingCard.author" values={{ authorName }} />
              </div>
            ) : null}
          </div>
        </div> */}
      </div>
    </NamedLink>
  );
};

SectionListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

SectionListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(SectionListingCardComponent);
