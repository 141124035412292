import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Button, IconEmailAttention, NamedLink } from '../../components';

import css from './ModalPendingGroups.module.css';

const PendingGroupsReminder = props => {
  const {
    className,
    quantity,
  } = props;

  const redirectLink = (
    <NamedLink className={css.helperLink} name="GroupsPage">
      <FormattedMessage id="ModalPendingGroups.redirectText" />
    </NamedLink>
  );

  return (
    <div className={className}>
      <IconEmailAttention className={css.modalIcon} />
      <p className={css.modalTitle}>
        <FormattedMessage id="ModalPendingGroups.pendingGroupsTitle" values={{quantity}}/>
      </p>
      <p className={css.modalMessage}>
        <FormattedMessage id="ModalPendingGroups.pendingGroupsText" />
      </p>

      <div className={css.bottomWrapper}>
        <p className={css.helperText}>
          { redirectLink }
        </p>
      </div>
    </div>
  );
};

export default PendingGroupsReminder;
