import React from 'react';
import { string, func, bool } from 'prop-types';
import classNames from 'classnames';

import { useConfiguration } from '../../context/configurationContext';

import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { displayPrice } from '../../util/configHelpers';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { isBookingProcessAlias } from '../../transactions/transaction';

import { camelCaseToTitleCase } from '../../util/string';

import { AspectRatioWrapper, Button, NamedLink, ResponsiveImage } from '../../components';

import css from './ListingCard.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

const PriceMaybe = props => {
  const { price, publicData, config, intl } = props;
  const { listingType } = publicData || {};
  const validListingTypes = config.listing.listingTypes;
  const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice && price) {
    return null;
  }

  const isBookable = isBookingProcessAlias(publicData?.transactionProcessAlias);
  const { formattedPrice, priceTitle } = priceData(price, config.currency, intl);
  return (
    <div className={css.price}>
      <div className={css.priceValue} title={priceTitle}>
        {formattedPrice}
      </div>
      {isBookable ? (
        <div className={css.perUnit}>
          <FormattedMessage id="ListingCard.perUnit" values={{ unitType: publicData?.unitType }} />
        </div>
      ) : null}
    </div>
  );
};

export const CategoryListingCardComponent = props => {
  const config = useConfiguration();
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    showAuthorInfo,
    isIframe,
    showEdit,
    showDelete,
    onDelete,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, publicData = {} } = currentListing.attributes || {};
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const setActivePropsMaybe = setActiveListing
    ? {
        onMouseEnter: () => setActiveListing(currentListing.id),
        onMouseLeave: () => setActiveListing(null),
      }
    : null;

  // Features list, change in order to show the listing public data
  const FEATURES = [
    'axleToAxle',
    'speed',
    'drawWeight',
  ];
  const computedFeatures = FEATURES.filter(f => publicData[f] != undefined);
  const tags = publicData?.tags || [];

  const imageVariants = firstImage?.attributes?.variants || [];
  const image = imageVariants['default'] ||imageVariants['square-small2x'] || imageVariants['square-small'] || imageVariants['landscape-crop2x'] || imageVariants['landscape-crop'];

  let listingProps = {
    name: 'ListingPage',
    params: { id, slug },
    ...(isIframe ? {to: { search: 'iframe'}} : {})
  };

  let editListingProps = {
    name: 'EditListingPage',
    params: { id, slug, type: 'edit', tab: 'details' }
  };

  let hasPrice = !!price;
  let hasImages = !!image;
  let hasDeliveryOption = publicData.deliveryPreference !== undefined;

  const optionalPublicData = [
    'shippingAddress',
    'location',
    'storedAddress'
  ];

  const obtainedParams = optionalPublicData.filter(f => publicData[f] != undefined);
  let hasDelivery = hasDeliveryOption && !!obtainedParams.length;

  let isDraft = publicData.isAssociated && (!hasPrice || !hasImages || !hasDelivery);

  return (
    <div className={classNames(css.categoryListingCardContainer, css.landingItem)}>
      <div>
      {isDraft ? <div className={css.draftOverlay}>
        <div>
          <FormattedMessage id="ManageListingCard.draftOverlayText" values={{listingTitle: title}}/>
        </div>
        <div>
          {showEdit ? <NamedLink {...editListingProps}>
            <Button>{intl.formatMessage({ id: 'ListingPage.finishListing' })}</Button>
          </NamedLink> : null}
        </div>
      </div> : null}
      <div className={css.mainInfo}>
        <div className={css.categoryTitle}>
          <div>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </div>
          {tags.length && <div className={css.tagsContainer}>
            {tags && tags?.map((tag, tag_index) => (
              <div className={css.tag} key={tag_index}>{tag}</div>
            ))}
          </div> || null}
        </div>
        {hasPrice ? <PriceMaybe price={price} publicData={publicData} config={config} intl={intl} />
          : <FormattedMessage id="ManageListingCard.priceNotSet" />}
      </div>
      <div className={css.categoryListingCardFeatures}>
        <div className={css.categoryListingCardFeatures}>
          {computedFeatures.length ? <div>
            {computedFeatures.map((feature, feature_index) => (
              <div className={css.featuresListContainer} key={feature_index}>
                <div className={css.featureTitle}>{camelCaseToTitleCase(feature)}</div>
                <div className={css.featureDetail}>{publicData[feature]}</div>
              </div>
            ))}
          </div> : null}
          <div>
            {image && image.url && <img className={css.categoryListingCardImage} src={image.url} />}
          </div>
        </div>
      </div>
      </div>
      <div className={classNames(css.categoryListingButtonContainer, {[css.categoryWithDelete]: showDelete})}>
        {!isDraft ? <>
          <NamedLink {...listingProps}>
            <Button>Detail</Button>
          </NamedLink>
          {showEdit ? <NamedLink {...editListingProps}>
            <Button>Edit</Button>
          </NamedLink> : null}
          {showDelete ? <Button onClick={onDelete}>Delete</Button> : null}
        </> : null}
        {/* <NamedLink {...listingProps}>
          <Button>Buy Now</Button>
        </NamedLink> */}
      </div>
      {/* <AspectRatioWrapper
        className={css.aspectRatioWrapper}
        width={aspectWidth}
        height={aspectHeight}
        {...setActivePropsMaybe}
      >
        <LazyImage
          rootClassName={css.rootForImage}
          alt={title}
          image={firstImage}
          variants={variants}
          sizes={renderSizes}
        />
      </AspectRatioWrapper>
      <div className={css.info}>
        <PriceMaybe price={price} publicData={publicData} config={config} intl={intl} />
        <div className={css.mainInfo}>
          <div className={css.title}>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </div>
          {showAuthorInfo ? (
            <div className={css.authorInfo}>
              <FormattedMessage id="ListingCard.author" values={{ authorName }} />
            </div>
          ) : null}
        </div>
      </div> */}
    </div>
  );
};

CategoryListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
  isIframe: false,
  showEdit: false,
  showDraft: false,
  showDelete: false,
};

CategoryListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,
  isIframe: bool,
  showEdit: bool,
  showDraft: bool,
  showDelete: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(CategoryListingCardComponent);
