import { LOCATION_CHANGED } from '../ducks/routing.duck';

// Create a Redux middleware from the given analytics handlers. Each
// handler should have the following methods:
//
// - trackPageView(canonicalPath, previousPath): called when the URL is changed
export const createMiddleware = handlers => store => next => action => {
  const { type, payload } = action;

  if (type === LOCATION_CHANGED) {
    const previousPath = store?.getState()?.routing?.currentCanonicalPath;
    const { canonicalPath } = payload;
    handlers.forEach(handler => {
      handler.trackPageView(canonicalPath, previousPath);
    });
  }

  next(action);
};

export const createGTagMiddleware = () => store => next => action => {
  const { type, payload } = action;

  if (type === LOCATION_CHANGED) {
    const previousPath = store?.getState()?.routing?.currentCanonicalPath;
    const { canonicalPath: page_path } = payload;

    try {
      if (previousPath && window.gtag && typeof window.gtag === 'function') {
        window.setTimeout(() => {
          // window.gtag('event', 'page_view', { page_path });
        }, 300);
      }
    } catch (e) {
      console.error('GTAG failed');
    }
  }

  next(action);
};
